export interface Media {
  id: number;
  pub_id: string;
  name: string;
  prompt: string;
  create_date: Date;
  created_by: {id: number; name: string};
  status: number;
  thumbnail: string;
  productImage?: File;
  productWithFrameImage?: File;
  transparentProductImage?: File;
  video_url: string;
  resolution: string;
  width: number;
  height: number;
  enhancePrompt?: boolean;

  // from server
  original_product_image_url?: string;
  original_product_image_location?: string;
  cleared_product_image_location?: string;
  cleared_product_image_url?: string;
  product_in_frame_image_location?: string;
  video_location?: string;
  thumbnail_location?: string;
  is_shared?: number;
  is_deleted?: number;
  is_downloaded?: number;
  thumbnail_url?: string;
  reason?: string;
  user_id?: number;
  error_code?: number;
  // created_by?: number;
  downloaded_time?: Date;
  is_auto_descriped?: number;
  is_auto_enhanced?: number;
  chosen_scene_id?: number;
  owner_user: {id: number; name: string};
  dynamics_description: string;

  scene_images?: SceneImage[];
  chosen_scene_image?: {
    description: string;
    dynamics_description: string;
    pub_id: string;
  };
}

export interface Scene {
  id: number;
  pub_id: string;
  description: string;
  enhanced_description: string;
  video_id: number;
}

export interface SceneImage {
  id: number;
  image_location: string;
  adjusted_image_location: string;
  pub_id: string;
  reason: string;
  status: number;
  scene?: Scene;
}

export enum SceneImageStatusEnum {
  PENDING = 0,
  COMPLETED = 1,
  FAILED = -1,
}

export enum MediaStatusEnum {
  DRAFT = 0,
  PROCESSING = 1,
  COMPLETED = 2,
  FAILED = -1,
}

export const MediaResolutions = [
  {res: '1024x1024', label: '1,024 x 1,024', iconClass: 'square'},
  {res: '1280x720', label: '1,280 x 720', iconClass: 'landscape-rectangle'},
  {res: '720x1280', label: '720 x 1,280', iconClass: 'portrait-rectangle'},
];
