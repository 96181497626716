import {MediaCard} from './components/MediaCard';
import {PageTitle} from '../../../../../_metronic/layout/core';
import {KTSVG} from '../../../../../_metronic/helpers';
import {Link} from 'react-router-dom';
import clsx from 'clsx';
import BlockUi from 'react-block-ui';
import {MediaLibraryQueryProvider, useMediaLibraryQuery} from './MediaLibraryQueryProvider';
import useMediaLibraryStore from './media-library.store';
import {EmptyPlaceholder} from '../../../../../shared/components/EmptyPlaceholder';
import {MediaPagination} from './components/MediaPagination';

const MediaLibrary = () => {
  const {setVideos, videos, reset, filters, updateFilters} = useMediaLibraryStore();
  const {isLoading, refetch, response, query} = useMediaLibraryQuery();

  return (
    <div className='h-100'>
      <PageTitle breadcrumbs={[]}>Media Library</PageTitle>

      <div className='app-container '>
        <header className='d-flex justify-content-between align-items-center mb-3'>
          <div className='d-flex align-items-center'>
            <h2>Your Completed Media</h2>
            <div className='ms-3 d-flex align-items-center'>
              {/* <VideosSearch /> */}

              <button
                className='btn btn-sm btn-icon btn-flex bg-body btn-color-gray-700 btn-active-color-primary fw-bold ms-2'
                onClick={() => {
                  refetch();
                }}
                title='Refresh models and reset cache'
              >
                <span className='svg-icon svg-icon-1'>
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr029.svg'
                    className='svg-icon-muted svg-icon-4'
                  />
                </span>
              </button>

              {/* {count > 0 && (
                <VideosSelectionOptions videos={response?.data} onReload={() => refetch()} />
              )} */}
            </div>
          </div>
          <div className='tools d-flex'>
            <ul className='nav nav-pills me-6 mb-2 mb-sm-0'>
              <li className='nav-item m-0'>{/* <VideoSorting /> */}</li>
              <li className='nav-item m-0'>{/* <VideosFilter /> */}</li>
            </ul>

            <Link
              to={`/rich-media/generate`}
              className={clsx('btn btn-primary btn-sm fw-bold ms-2')}
            >
              Generate New Media
            </Link>
          </div>
        </header>

        <BlockUi tag='div' blocking={isLoading} message='Loading medias ...' keepInView>
          <div className='min-h-200px'>
            {response?.data?.length === 0 && (
              <EmptyPlaceholder
                label='No medias found!'
                description='Try generating a new media or search with different keywords'
              />
            )}

            <div className='row g-5'>
              {response?.data?.map((media) => (
                <div key={media.pub_id} className='col-sm-4 col-lg-3'>
                  <MediaCard video={media} />
                </div>
              ))}
            </div>
          </div>
        </BlockUi>

        <MediaPagination />
      </div>
    </div>
  );
};

export const MediaLibraryPage = () => {
  return (
    <MediaLibraryQueryProvider>
      <MediaLibrary />
    </MediaLibraryQueryProvider>
  );
};

export default MediaLibrary;
