import React, {useEffect, useState} from 'react';
import useMediaWizardStore from '../../media-wizard.store';
import {InputText} from 'primereact/inputtext';
import {RadioButton} from 'primereact/radiobutton';
import {CanvasEditor} from '../../../../../canvas-editor/CanvasEditor';
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers';
import {Checkbox} from 'primereact/checkbox';
import {Next} from 'react-bootstrap/esm/PageItem';
import {Skeleton} from 'primereact/skeleton';
import SceneImageCard from './SceneImage';
import {fetchImageAsFile, parseError, scrollToTop} from '../../../../../../../shared/utils';
import {SceneImage} from '../../../../media.types';
import clsx from 'clsx';
import {InputTextarea} from 'primereact/inputtextarea';
import {selectSceneImage} from '../../../../media.api';

const DynamicsStep = () => {
  const {prev, next, media: video, updateMedia: updateVideo, selectedScene} = useMediaWizardStore();
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');
  const width = video.resolution.split('x')[0];
  const height = video.resolution.split('x')[1];
  const orientation = +width >= +height ? 'landscape' : 'portrait';
  const [type, setType] = useState<'auto' | 'manual'>('auto');

  const submit = async () => {
    setError('');

    if (type === 'manual' && !video.dynamics_description) {
      setError('Please fill description field');
      scrollToTop();
      return;
    }

    try {
      if (!selectedScene || !selectedScene.pub_id) return;

      setSubmitting(true);

      const selectedAdjustedImage = await fetchImageAsFile(
        selectedScene.image_location,
        'image_location.png'
      );

      const res = await selectSceneImage(
        selectedScene.pub_id,
        video.pub_id,
        video.dynamics_description,
        selectedAdjustedImage
      );

      setSubmitting(false);
    } catch (error) {
      scrollToTop();
      setSubmitting(false);

      setError(`Error while submitting selected scene (Details: ${parseError(error)})`);
    }

    next();
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className='container mw-1000px'>
      {error && <div className='alert alert-danger mb-5'>{error}</div>}

      <section className='mb-10'>
        <h2 className='fw-bold d-flex align-items-center text-gray-900'>Ad Dynamics</h2>
        <div className=' fs-5 mb-10 fw-semibold text-muted'>
          Help our AI Agent understand your desired dynamics in the final result.
        </div>

        <div className='row g-5'>
          <div className='col-md-8 text-center'>
            <img
              src={selectedScene?.image_location}
              alt='selectedScene'
              className='rounded'
              style={{
                height: orientation === 'portrait' ? '60vh' : 'auto',
                width: orientation === 'portrait' ? 'auto' : '95%',
              }}
            />
          </div>

          <div className='col-md-4'>
            <fieldset className='mb-2'>
              <div className='d-flex align-items-center'>
                <RadioButton
                  inputId='auto'
                  name='type'
                  value='auto'
                  onChange={(e) => {
                    updateVideo({dynamics_description: ''});
                    setType('auto');
                  }}
                  checked={type === 'auto'}
                />
                <label className='ms-3 py-3' htmlFor='auto'>
                  <div className='fs-5 fw-bold'>Automatic</div>
                  <div className='text-muted'> Our AI Agent can take over</div>
                </label>
              </div>
            </fieldset>

            <fieldset className='mb-2'>
              <div className='d-flex align-items-center'>
                <RadioButton
                  inputId='manual'
                  name='type'
                  value='manual'
                  onChange={(e) => {
                    setType('manual');
                  }}
                  checked={type === 'manual'}
                />
                <label
                  className={clsx('ms-3 py-3', {
                    required: error && type === 'manual',
                  })}
                  htmlFor='manual'
                >
                  <div className='fs-5 fw-bold'>Manual</div>
                  <div className='text-muted'>
                    Describe what you would like to happen in the scene
                  </div>
                </label>
              </div>

              {type === 'manual' && (
                <div className='ps-10'>
                  <InputTextarea
                    placeholder='e.g. camera move right, fade in, sunrise on the left, birds flying in the background, autumn leaves falling,......'
                    name='desc'
                    id='desc'
                    rows={8}
                    autoFocus
                    className={clsx(
                      'bg-light mb-3 mb-lg-0 border-0 w-100  fw-bold text-gray-700 fs-6'
                    )}
                    value={video.dynamics_description}
                    onChange={(e) => updateVideo({dynamics_description: e.target.value})}
                  />
                </div>
              )}
            </fieldset>
          </div>
        </div>
      </section>

      <footer className='d-flex justify-content-between'>
        <button className='btn btn-light ' onClick={prev}>
          <span className='d-flex align-items-center'>
            <i className='me-2 ki-duotone ki-arrow-left fs-3'>
              <span className='path1' />
              <span className='path2' />
            </i>
            Back
          </span>
        </button>

        <button className='btn btn-primary' onClick={submit}>
          {!submitting && (
            <span className='d-flex align-items-center'>
              Generate Media
              <i className='ms-2 ki-duotone ki-arrow-right fs-3'>
                <span className='path1' />
                <span className='path2' />
              </i>
            </span>
          )}
          {submitting && (
            <span className='indicator-progress ' style={{display: 'block'}}>
              Please Wait ...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </footer>
    </div>
  );
};

export default DynamicsStep;
