import axios from 'axios';
import {getAuth} from '../auth';
import {AppLocator} from '../../../app-locator';

const API_URL =
  AppLocator.forceUsingRealApi || process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_URL
    : process.env.REACT_APP_DEV_API_URL;

export const uploadEditedModel = async (
  pub_id: string,
  file: File,
  name: string,
  setProgress: (progress: number) => void
) => {
  const formData = new FormData();
  formData.append('model', file);
  formData.append('name', name);

  await axios({
    method: 'POST',
    url: `${API_URL}/models/edit/${pub_id}`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${getAuth()?.api_token}`,
    },
    onUploadProgress: (progressEvent) => {
      const progress = (progressEvent.loaded / (progressEvent.total || 1)) * 50;

      if (setProgress) setProgress(progress);
    },
  });
};
