import {useEffect, useRef, useState} from 'react';
import useMediaWizardStore from '../../media-wizard.store';
import {MediaStatusEnum, SceneImageStatusEnum} from '../../../../media.types';
import {Skeleton} from 'primereact/skeleton';
import {
  getRandomString,
  parseError,
  pickMessageByKey,
  scrollToTop,
} from '../../../../../../../shared/utils';
import {getSingleMedia} from '../../../../media.api';
import clsx from 'clsx';
import SpinkitLoader from '../../../../../../../shared/components/SpinkitLoader';
import {Link} from 'react-router-dom';

const PROCESSING_MSGS = [
  'Hold tight! Your amazing ad is cooking up. It might take a minute, but it&#39;s worth the wait!',
  'Your masterpiece is in the works! It might take a minute, so why not take a quick break?',
  'Magic is happening! Your ad is being crafted and will be ready in a minute.',
  'Good things come to those who wait! Your ad is being created and will be ready shortly.',
  'Your ad is baking in the creative oven. It&#39;ll be ready in a minute, just hang tight!',
  'The wheels are turning! Your ad is on the way. It might take a minute, but it&#39;ll be awesome!',
  'Your ad is being woven together. It may take a minute, but it will be worth every second!',
  'Excitement building! Your ad is coming together. Just be patience, please.',
  'Your cinematic experience is being crafted. It may take a minute, so sit back and relax!',
  'Your ad is being crafted with extra love and care. It might take a minute, but it’ll be worth the wait!',
];

const MediaFinalStep = () => {
  const {
    prev,
    next,
    media: video,
    setMedia: setVideo,
    updateMedia: updateVideo,
  } = useMediaWizardStore();
  const [error, setError] = useState('');
  const intervalRef = useRef<number | null>(null); // Use useRef to store the interval ID
  const [loading, setLoading] = useState(false);
  const width = video.resolution.split('x')[0];
  const height = video.resolution.split('x')[1];
  const orientation = +width > +height ? 'landscape' : 'portrait';
  const completed = video.status === MediaStatusEnum.COMPLETED;

  const get = async () => {
    if (!video.pub_id) return;

    try {
      setLoading(true);

      const res = await getSingleMedia(video.pub_id);
      const _video = res.video[0];
      setVideo(_video);

      setLoading(false);
    } catch (error) {
      setError(`Error while loading, (details: ${parseError(error)}`);
    }
  };
  const startInterval = () => {
    console.log('startInterval');
    if (intervalRef.current === null) {
      intervalRef.current = window.setInterval(() => {
        get();
      }, 3000);
    }
  };

  const stopInterval = () => {
    console.log('stopInterval');
    if (intervalRef.current !== null) {
      clearInterval(intervalRef.current);
      intervalRef.current = null; // Reset ref to avoid multiple intervals
    }
  };

  useEffect(() => {
    startInterval();
    scrollToTop();

    return () => stopInterval();
  }, []);

  useEffect(() => {
    if (video.status === MediaStatusEnum.COMPLETED) {
      stopInterval();
    }
  }, [video]);

  return (
    <div className='container mw-800px'>
      {error && <div className='alert alert-danger mb-5'>{error}</div>}

      <header className=''>
        {completed && (
          <>
            <h2 className='fw-bold d-flex align-items-center text-gray-900'>Media is ready!</h2>
            <div className=' fs-6  text-muted'>
              Your generated media ad is ready... check it out.
            </div>
          </>
        )}

        {!completed && (
          <div className='d-flex  justify-content-start align-items-top '>
            <div>
              <SpinkitLoader />
            </div>
            <div className='ms-10'>
              <h2 className='fw-bold d-flex align-items-center text-gray-900 '>
                Generating your media!
                {loading && <i className='fa fa-refresh fa-spin ms-4 text-primary'></i>}
              </h2>
              <div className=' fs-6 mb-10 text-muted'>
                {pickMessageByKey(PROCESSING_MSGS, video.pub_id)}
              </div>

              <Link to={'/rich-media/list'} className='btn btn-primary'>
                Go to Library
              </Link>
            </div>
          </div>
        )}
      </header>

      <section className='my-10'>
        {completed && (
          <video
            id={video.pub_id + ''}
            poster={video.thumbnail_url}
            className={clsx('rounded')}
            style={{
              height: orientation === 'portrait' ? '60vh' : 'auto',
              width: orientation === 'portrait' ? 'auto' : '100%',
            }}
            autoPlay
            controls
            controlsList='nodownload'
            muted
          >
            <source src={video.video_url} type='video/mp4' />
          </video>
        )}

        {!completed && (
          <Skeleton className='w-75 rounded  min-h-400px aspect-ratio-box-content mx-auto' />
        )}
      </section>

      <footer className='d-flex justify-content-between'>
        <button className='btn btn-light ' onClick={prev}>
          <span className='d-flex align-items-center'>
            <i className='me-2 ki-duotone ki-arrow-left fs-3'>
              <span className='path1' />
              <span className='path2' />
            </i>
            Back
          </span>
        </button>

        <div></div>

        {/* <button className='btn btn-primary' onClick={() => {}}>
          <span className='d-flex align-items-center'>
            Continue
            <i className='ms-2 ki-duotone ki-arrow-right fs-3'>
              <span className='path1' />
              <span className='path2' />
            </i>
          </span>
        </button> */}
      </footer>
    </div>
  );
};

export default MediaFinalStep;
