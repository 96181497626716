/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import {useState, useEffect} from 'react';
import {Dropdown} from 'primereact/dropdown';

import {useSearchParams} from 'react-router-dom';
import {MenuComponent} from '../../../../../../_metronic/assets/ts/components';
import {initialQueryState, KTSVG} from '../../../../../../_metronic/helpers';

import {Calendar} from 'primereact/calendar';
import {Tooltip} from 'primereact/tooltip';
import {useAuth} from '../../../../auth';
import {getUsers} from '../../../../admin/api/users-api';
import {sortByProp} from '../../../../../../shared/utils';
import useTemplatesStore from '../templates.store';

export const templatesStatuses = [
  {id: 'draft', label: 'Draft'},
  {id: 'published', label: 'Published'},
];

export function TemplatesFilters() {
  const [isLoading] = useState(false);
  const {currentUser} = useAuth();
  const {updateFilters, filters} = useTemplatesStore();

  const [status, setStatus] = useState<string | undefined>(filters?.filter?.status);
  const [isShared, setIsShared] = useState<boolean | undefined>();
  const [dateFrom, setDateFrom] = useState<Date>();
  const [dateTo, setDateTo] = useState<Date>();
  const [users, setUsers] = useState<any[]>([]);
  const [selectedUser, setSelectedUser] = useState('');

  const [search] = useSearchParams();
  const statusFilter = search.get('status');

  const resetData = () => {
    setStatus(undefined);
    setIsShared(false);
    setDateFrom(undefined);
    setDateTo(undefined);
    updateFilters({filter: undefined, ...initialQueryState});
  };

  const filterData = () => {
    if (!status && !isShared && !dateFrom && !dateTo) return;

    const filters: any = {
      status,
    };
    updateFilters({
      filter: {...filters},
      ...initialQueryState,
    });
  };

  useEffect(() => {
    if (!statusFilter) return;

    setStatus(statusFilter);
  }, [statusFilter]);

  useEffect(() => {
    MenuComponent.reinitialization();
  }, []);

  return (
    <>
      {/* Toggle */}
      <button
        type='button'
        className='btn btn-sm btn-icon btn-flex bg-body btn-color-gray-700 btn-active-color-primary  fw-bold'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        data-kt-menu-static='true'
      >
        <KTSVG
          path='/media/icons/duotune/general/gen031.svg'
          className={clsx('svg-icon-2', {'text-primary': filters.filter})}
        />
        {/* Filter */}
      </button>

      {/* Menu */}
      <section className='menu menu-sub menu-sub-dropdown w-400px w-md-425px' data-kt-menu='true'>
        <header className='flex align-items-center justify-content-between px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
          <div
            title='Close filters'
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            data-kt-menu-dismiss='true'
          >
            <i className='la la-close fs-3'></i>
          </div>
        </header>

        <section className='separator border-gray-200'></section>

        <form className='px-7 py-5' data-kt-user-table-filter='form'>
          <fieldset className='flex flex align-items-center mb-2'>
            <label className='w-200px form-label fs-6 fw-bold'>Status:</label>

            <Dropdown
              // placeholder='Choose...'
              optionLabel='label'
              optionValue='id'
              name='status'
              id='status'
              className='bg-light mb-3 mb-lg-0 border-0 w-100 fw-bold text-gray-700'
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              options={templatesStatuses}
              itemTemplate={(item) => <div className='capitalized'>{item?.label}</div>}
              valueTemplate={(item) => <div className='capitalized'>{item?.label}&nbsp;</div>}
              showClear
            />
          </fieldset>

          <div className='separator my-4' />

          <footer className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Reset
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Apply
            </button>
          </footer>
        </form>
      </section>
    </>
  );
}
