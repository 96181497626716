import heic2any from 'heic2any';

export const convertHEICtoPNG = async (file: File) => {
  const conversionResult = await heic2any({blob: file});

  return new File([conversionResult as Blob], file.name.replace('.heic', '.png'), {
    type: 'image/png',
  });
};

export function convertImageFileToBase64(file: File): Promise<string | null> {
  return new Promise((resolve, reject) => {
    if (!file) {
      reject('File is missing or invalid.');
      return;
    }

    const reader = new FileReader();

    reader.onload = (event) => {
      if (event.target && typeof event.target.result === 'string') {
        resolve(event.target.result);
      } else {
        reject('Failed to convert file to base64.');
      }
    };

    reader.onerror = () => {
      reject('Error occurred while reading the file.');
    };

    reader.readAsDataURL(file);
  });
}

export const convertHeicFilesIfExist = async (files: File[]) => {
  return await Promise.all(
    files.map(async (file) => {
      if (file.name.toLowerCase().endsWith('.heic')) return await convertHEICtoPNG(file);
      return file;
    })
  );
};
