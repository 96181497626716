import {FC} from 'react';
import clsx from 'clsx';
import {Link} from 'react-router-dom';
import {useLocation} from 'react-router';
import {checkIsActive, KTIcon, WithChildren} from '../../../helpers';

type Props = {
  to: string;
  title: string;
  icon?: string;
  fontIcon?: string;
  hasBullet?: boolean;
  disableActiveStyle?: boolean;
};

const AsideMenuSolidItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
  disableActiveStyle = false,
}) => {
  const {pathname} = useLocation();
  const isActive = checkIsActive(pathname, to) && !disableActiveStyle;

  return (
    <div className='menu-item mb-2'>
      <Link className={clsx('menu-link without-sub ', {active: isActive})} to={to}>
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}

        {fontIcon && <i className={clsx('bi fs-2', fontIcon)}></i>}
        <span className='menu-title ' style={{color: '#9b6ff7'}}>
          {title}
        </span>

        {icon && (
          <span className='menu-icon'>
            <i className='ki-duotone ki-plus-circle fs-1 ' style={{color: '#9b6ff7'}}>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>
          </span>
        )}
      </Link>
      {children}
    </div>
  );
};

export {AsideMenuSolidItem};
