import {useState} from 'react';
import clsx from 'clsx';
import {SceneImage, SceneImageStatusEnum} from '../../../../media.types';
import useMediaWizardStore from '../../media-wizard.store';
import {useAuth} from '../../../../../auth';
import {Skeleton} from 'primereact/skeleton';
import {regenerateScenes} from '../../../../media.api';

interface Props {
  sceneImage?: SceneImage;
  onRegeneratingClicked?: () => void;
}

const SceneImageCard = ({sceneImage, onRegeneratingClicked}: Props) => {
  const {selectedScene, setSelectedScene, media: video} = useMediaWizardStore();
  const selected = selectedScene?.id === sceneImage?.id;
  const {currentUser} = useAuth();
  const width = video.resolution.split('x')[0];
  const height = video.resolution.split('x')[1];
  const orientation = +width > +height ? 'landscape' : 'portrait';
  const [regenerating, setRegenerating] = useState(false);

  const regenerate = async () => {
    setRegenerating(true);
    const res = await regenerateScenes(video.pub_id, sceneImage?.pub_id);
    setRegenerating(false);

    onRegeneratingClicked && onRegeneratingClicked();
  };

  if (!sceneImage) return <div>not ready!</div>;

  return (
    <div className='p-5'>
      <div className='hover-container'>
        <div
          className={clsx('rounded-3', {
            'border-2 border-primary shadow-primary ': selected,
          })}
          onClick={() => {
            setSelectedScene(sceneImage);
          }}
        >
          {sceneImage.status === SceneImageStatusEnum.COMPLETED && (
            <img className='rounded w-100' src={sceneImage.image_location} alt='scene' />
          )}
          {sceneImage.status !== SceneImageStatusEnum.COMPLETED && (
            <Skeleton
              className='rounded'
              width={orientation === 'portrait' ? '100%' : '100%'}
              height={orientation === 'portrait' ? '50vh' : '260px'}
            />
          )}

          {selected && (
            <div className='position-absolute top-0 right-0 p-2 '>
              <i className='ki-solid ki-check-circle text-primary bg-white rounded-circle  fs-1'></i>
            </div>
          )}
        </div>
        {currentUser?.is_super_admin && (
          <div className='text-muted mt-2'>{sceneImage.scene?.description}</div>
        )}

        <div className='visible-on-hover mt-5 text-center'>
          {sceneImage.status === SceneImageStatusEnum.COMPLETED && (
            <div className='w-100 text-center mb-10'>
              <button className='btn btn-info btn-sm' onClick={regenerate}>
                {!regenerating && <span className='d-flex align-items-center'>More like this</span>}
                {regenerating && (
                  <span className='indicator-progress ' style={{display: 'block'}}>
                    Please Wait ...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SceneImageCard;
