/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react';
import dayjs from 'dayjs';
import {convertSecondsToReadableTime} from '../../../../../../shared/date-util';
import clsx from 'clsx';
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers';
import {Dialog} from 'primereact/dialog';
import {useAuth} from '../../../../auth';
import {Link, useNavigate} from 'react-router-dom';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import {downloadVideo, getRandomString, parseError} from '../../../../../../shared/utils';
import {confirmDialog} from 'primereact/confirmdialog';
import {toast} from 'react-toastify';

import {useCardsSelection} from '../../../../models-management/pages/models-list/core/CardsSelectionProvider';
import HasPermission from '../../../../auth/HasPermission';
import {deleteDialogProps} from '../../../../shared/types';
import {deleteTemplate, deleteVideo} from '../../../../videos/vidoes.api';
import {Video, VideoStatusEnum} from '../../../../videos/vidoes.types';
import {Media, MediaStatusEnum} from '../../../media.types';
import {sleep} from 'react-query/types/core/utils';
import {deleteMedia} from '../../../media.api';
import {useMediaLibraryQuery} from '../MediaLibraryQueryProvider';
import {ModalLayout} from '../../../../shared/components/ModalLayout';
import {MediaInformationWindow} from './MediaInformationWindow';
import './MediaCard.scss';
import {MediaEditWindow} from './MediaEditWindow';
dayjs.extend(relativeTime as any);

interface Props {
  video: Media;
  isFocused?: boolean;
  isTemplate?: boolean;
  onUpdate?: () => void;
}

export const MediaCard = ({video, onUpdate}: Props) => {
  const [media, setMedia] = useState(video);
  const {
    id,
    pub_id,
    name,
    thumbnail_url,
    original_product_image_url,
    video_url,
    status,
    is_deleted,
  } = media;
  const [showVideo, setShowVideo] = useState(false);
  const [showWindow, setShowWindow] = useState(false);
  const [showEditWindow, setShowEditWindow] = useState(false);
  const {currentUser} = useAuth();
  const navigate = useNavigate();
  const [deleting, setDeleting] = useState(false);
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState<number>(0);
  const {checkSelected} = useCardsSelection();
  const selected = checkSelected(pub_id + '');

  const {isLoading, refetch, response, query} = useMediaLibraryQuery();

  const deleteBtnClicked = () => {
    confirmDialog({
      ...deleteDialogProps,
      message: `Are you sure you want to delete?`,
      accept: () => {
        submitDelete();
      },
      reject: () => {},
    });
  };

  const submitDelete = async () => {
    if (!video) return;

    try {
      setDeleting(true);

      await deleteMedia(media.pub_id);

      toast.success('Video deleted successfully!');

      refetch();

      onUpdate && onUpdate();
    } catch (error) {
      setDeleting(false);
      alert(parseError(error));
    }
    setDeleting(false);
  };

  const download = async () => {
    if (media.status !== MediaStatusEnum.COMPLETED) return;
    try {
      await downloadVideo({
        videoUrl: media.video_url,
        fileName: media.name || 'Vree Generative Media',
        onProgress,
      });
    } catch (error: any) {
      toast.error('Download failed, please contact support!');
    }
  };
  const onProgress = (progressEvent: ProgressEvent) => {
    const total = progressEvent.total ?? 0;
    const current = progressEvent.loaded;
    const percentCompleted = Math.round((current / total) * 100);
    setDownloadProgress(percentCompleted);
  };

  return (
    <article
      onBlur={() => setShowVideo(false)}
      className={clsx('card card-bordered card-flush shadow-sm hover-container', {
        'border-primary border-1 shadow-primary shadow-sm': selected,
      })}
    >
      <header className='position-absolute top-0 left-0 right-0 gradient-background-hover text-white  d-flex justify-content-between align-items-center  p-4 py-4 rounded-top z-index-999'>
        <div>
          <div className=' fs-6 fw-semibold capitalized text-shadow'>{name || ''}</div>
        </div>
        <div className='d-flex visible-on-hover align-items-center '>
          {!showVideo && (
            <>
              {/* // <a
            //   title='Hide Video'
            //   className='cursor-pointer w-30px h-30px rounded-circle bg-hover-primary  d-flex flex-center'
            //   onClick={(e) => {
              //     e.stopPropagation();
            //     setShowVideo(false);
            //   }}
            // >
            //   <i className='ki-outline ki-cross-square text-white fs-1'></i>
            // </a> */}

              <a
                title='Edit name'
                className='cursor-pointer w-30px h-30px rounded-circle bg-hover-primary  d-flex flex-center my-1'
                onClick={(e) => {
                  e.stopPropagation();
                  setShowEditWindow(true);
                }}
              >
                <i className='ki-outline ki-pencil text-white fs-3'></i>
              </a>

              <a
                title='Info'
                className='cursor-pointer w-30px h-30px rounded-circle bg-hover-primary  d-flex flex-center my-1'
                onClick={(e) => {
                  e.stopPropagation();
                  setShowInfoWindow(true);
                }}
              >
                <i className='ki-outline ki-information-4 text-white fs-3'></i>
              </a>
            </>
          )}
        </div>
      </header>

      <div
        onClick={async () => {
          if (media.status !== MediaStatusEnum.COMPLETED) return;

          setShowVideo(true);
          setTimeout(() => {
            document.getElementsByTagName('video')[0]?.focus();
          }, 400);
        }}
        className='video-thumbnail-container aspect-ratio-box min-h-200px bg-light  rounded z-2'
        style={{
          backgroundImage: `url('${thumbnail_url || original_product_image_url}')`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          filter: media.status === MediaStatusEnum.DRAFT ? '' : showVideo ? 'blur(2px)' : '',
        }}
      >
        <div>
          <>
            {media.status === MediaStatusEnum.PROCESSING && (
              <>
                <div className='play-icon bg-primary'>
                  <i className='fa  fa-spinner fa-spin fs-4 text-white'></i>{' '}
                </div>
                <div className='text-info text-sm text-center mt-15 center-center w-100 px-2  '>
                  {/* Processing */}
                </div>
              </>
            )}

            {/* {media.status === GenerativeVideoStatusEnum.DRAFT && (
              <>
                <div className='play-icon bg-info'>
                  <i className='fa fa-info fs-4 text-white'></i>{' '}
                </div>
                <div className='text-info text-sm text-center mt-15 center-center w-100 px-2  '>
                  Click to select scene and generate video
                </div>
              </>
            )} */}
          </>
        </div>
      </div>

      {showVideo && (
        <div className='aspect-ratio-box position-absolute top-0 z-far'>
          <video
            id={id + ''}
            poster={thumbnail_url || original_product_image_url}
            className='w-100 rounded  aspect-ratio-box-content '
            autoPlay
            controls
            controlsList='nodownload'
            muted
            onEnded={() => setShowVideo((v) => !v)}
          >
            <source src={video_url} type='video/mp4' />
          </video>
        </div>
      )}

      {!showVideo && (
        <footer className=' position-absolute bottom-0 left-0 right-0 gradient-background-reverse-hover text-white z-3 d-flex justify-content-between align-items-center  p-4 py-4 rounded-bottom '>
          <div className=' '>
            <div>
              {is_deleted && <span className=' badge badge-danger'>Deleted</span>}
              {!is_deleted && status === MediaStatusEnum.DRAFT && (
                <span className=' badge badge-info'>Draft</span>
              )}
              {!is_deleted && status === MediaStatusEnum.PROCESSING && (
                <span className=' badge badge-primary'>Processing</span>
              )}
              {/* {!is_deleted && status === GenerativeVideoStatusEnum.COMPLETED && (
                <span className=' badge badge-success'>Done</span>
              )} */}
              {!is_deleted && status === MediaStatusEnum.FAILED && (
                <span
                  className=' badge badge-light-danger cursor-pointer'
                  title={media.reason}
                  onClick={() => toast.warning('Failure details: ' + media.reason)}
                >
                  Failed
                </span>
              )}
            </div>
          </div>
          <div className='d-flex align-items-center visible-on-hover'>
            {downloadProgress > 0 && downloadProgress < 100 && (
              <div className=''>({downloadProgress}%)</div>
            )}

            {media.status === MediaStatusEnum.DRAFT && (
              <a
                title='Edit'
                className='cursor-pointer w-30px h-30px rounded-circle bg-hover-primary  d-flex flex-center my-1'
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/rich-media/video/${pub_id}`);
                }}
              >
                <i className='ki-outline ki-notepad-edit text-white fs-3'></i>
              </a>
            )}

            {media.status === MediaStatusEnum.COMPLETED && (
              <a
                title='Download'
                className='cursor-pointer w-30px h-30px rounded-circle bg-hover-info  d-flex flex-center my-1'
                onClick={(e) => {
                  e.stopPropagation();
                  download();
                }}
              >
                {!(downloadProgress > 0 && downloadProgress < 100) && (
                  <i className='ki-outline ki-cloud-download text-white fs-2'></i>
                )}
                {downloadProgress > 0 && downloadProgress < 100 && (
                  <div className='position-relative'>
                    <i className='ki-outline ki-loading text-white fa fa-spin fs-2'></i>
                  </div>
                )}
              </a>
            )}

            {/* <a
              title='Share'
              className='cursor-pointer w-30px h-30px rounded-circle bg-hover-primary  d-flex flex-center my-1'
              onClick={(e) => {
                e.stopPropagation();
                setShowShareWindow(true);
              }}
            >
              <i className='bi bi-share text-white fs-3'></i>
            </a> */}

            <a
              title='Delete'
              className='cursor-pointer w-30px h-30px rounded-circle bg-hover-danger  d-flex flex-center my-1'
              onClick={(e) => {
                e.stopPropagation();
                deleteBtnClicked();
              }}
            >
              <i className='ki-outline ki-trash text-white fs-3'></i>
            </a>

            {/* <a
              title='Regenerate'
              className='cursor-pointer w-30px h-30px rounded-circle bg-hover-primary  d-flex flex-center my-1'
              onClick={(e) => {
                e.stopPropagation();
                setShowShareWindow(true);
              }}
            >
              <i className='ki-outline ki-arrow-circle-right text-white fs-3'></i>
            </a> */}
          </div>
        </footer>
      )}

      <Dialog
        modal
        dismissableMask
        closable={false}
        visible={showInfoWindow}
        onHide={() => setShowInfoWindow(false)}
      >
        <ModalLayout
          onClose={() => setShowInfoWindow(false)}
          showHeader
          showFooter
          showCloseButton
          title={`Information`}
        >
          <MediaInformationWindow video={video} />
        </ModalLayout>
      </Dialog>

      <Dialog
        modal
        dismissableMask
        closable={false}
        visible={showEditWindow}
        onHide={() => setShowEditWindow(false)}
      >
        <MediaEditWindow
          submitComplete={(updatedMedia) => {
            setShowEditWindow(false);

            if (updatedMedia) setMedia(updatedMedia); // optimistic update
          }}
          video={video}
        />
      </Dialog>
    </article>
  );
};
