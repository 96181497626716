import React from 'react';
import dayjs from 'dayjs';
import {Image} from 'primereact/image';
import {Media} from '../../../media.types';
import {useAuth} from '../../../../auth';

interface Props {
  video: Media;
}
export function MediaInformationWindow({video}: Props) {
  const {currentUser} = useAuth();
  return (
    <div>
      <fieldset className='mb-2 d-flex'>
        <label className='fw-bold w-150px text-dark'>Name: </label>
        <div>{video.name || '-Not Provided-'}</div>
      </fieldset>
      <fieldset className='mb-2 d-flex'>
        <label className='fw-bold w-150px text-dark'>Video Resolution:</label>
        <div>{video.resolution}</div>
      </fieldset>
      <fieldset className='mb-2 d-flex'>
        <label className='fw-bold w-150px text-dark'>Auto described</label>
        <div>{video.is_auto_descriped ? 'Yes' : 'No'}</div>
      </fieldset>
      <fieldset className='mb-2 d-flex'>
        <label className='fw-bold w-150px text-dark'>Auto dynamics</label>
        <div>{video.is_auto_enhanced ? 'Yes' : 'No'}</div>
      </fieldset>
      <hr />
      <fieldset className='mb-2 d-flex'>
        <label className='fw-bold w-150px text-dark'>Creation Date: </label>
        <div>{dayjs(video.create_date).format('DD/MM/YYYY - H:mm a')}</div>
      </fieldset>
      <fieldset className='mb-2 d-flex'>
        <label className='fw-bold w-150px text-dark'>Created By:</label>
        <div>{video.created_by?.name}</div>
      </fieldset>
      <fieldset className='mb-2 d-flex'>
        <label className='fw-bold w-150px text-dark'>Owner:</label>
        <div>{video.owner_user?.name}</div>
      </fieldset>
      <hr />

      {currentUser?.is_super_admin && (
        <section>
          <fieldset className='mb-6 '>
            <label className='fw-bold w-200px text-dark'>Description:</label>
            <div className='ps-3'>{video.chosen_scene_image?.description || '-'}</div>
          </fieldset>

          <fieldset className='mb-2 '>
            <label className='fw-bold w-200px text-dark'>Dynamics description:</label>
            <div className='ps-3'>{video.chosen_scene_image?.dynamics_description || '-'}</div>
          </fieldset>

          <hr />
        </section>
      )}

      <div className='d-flex'>
        <div className='fw-bold w-150px text-dark'>Images:</div>
        {/* <article className='me-3'>
          <div className='mb-2'>Product Image:</div>
          <Image src={video.cleared_product_image_url} alt='Image' width='100' preview />
        </article> */}

        <article className='me-3'>
          <div className='mb-2'>Original Image:</div>
          <Image src={video.original_product_image_url} alt='Image' width='100' preview />
        </article>

        {video.thumbnail_url && (
          <article className='me-3'>
            <div className='mb-2'>Chosen Scene:</div>
            <Image src={video.thumbnail_url} alt='Image' width='100' preview />
          </article>
        )}
      </div>
    </div>
  );
}
