import {Dropdown} from 'primereact/dropdown';
import React, {useEffect, useState} from 'react';
import useMediaWizardStore from '../../media-wizard.store';
import {MediaResolutions} from '../../../../media.types';
import './MediaStartStep.scss';
import ImageUpload from '../../../../../models-management/pages/create-model/components/ImageUpload';
import {
  dataURLtoFile,
  imageFileToDataURL,
  parseError,
  scrollToTop,
  sleep,
} from '../../../../../../../shared/utils';
import {removeImageBackground} from '../../../../media.api';
import {ImageType} from 'react-images-uploading';
import {image} from 'html2canvas/dist/types/css/types/image';
import CardsSelector from '../../../../../../../shared/components/CardsSelector';

const VALIDATION_MSG = 'Please select the product image and select creative resolution';
const MediaStartStep = () => {
  const {media: video, updateMedia: updateVideo, next} = useMediaWizardStore();
  const [imagesList, setImagesList] = useState<File[]>();
  const [defaultImagesList, setDefaultImagesList] = useState<any[]>([]);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [progress, setProgress] = useState(0);
  const [isDefaultImage, setIsDefaultImage] = useState(false);

  const submit = async () => {
    if (((!imagesList || imagesList?.length === 0) && !isDefaultImage) || !video.resolution)
      return setError(VALIDATION_MSG);

    if (isDefaultImage) return next();

    try {
      setSubmitting(true);

      const payload = {
        resolution: video.resolution,
        image: video.productImage,
      };

      const file = await removeImageBackground(payload, setProgress);

      updateVideo({transparentProductImage: file});

      // await sleep(800);

      setSubmitting(false);

      next();
    } catch (error) {
      setSubmitting(false);
      setError(`Error while removing background (Details:${parseError(error)})`);
      scrollToTop();
    }
  };

  useEffect(() => {
    const updateDefaultImage = async () => {
      if (!video.productImage) return;

      const image: ImageType = {
        dataURL: await imageFileToDataURL(video.productImage),
        file: video.productImage,
      };
      setDefaultImagesList([image]);
      setIsDefaultImage(true);
    };

    updateDefaultImage();
  }, []);

  useEffect(() => {
    console.log('images list changed', imagesList);

    if (!imagesList || imagesList.length === 0) {
      return;
    }
    // setDefaultImagesList([]);
    setIsDefaultImage(false);
    const file = imagesList[0];
    const namedFile = new File([file], 'original_product_image.png', {type: file.type});
    updateVideo({productImage: namedFile});
  }, [imagesList]);

  return (
    <div className='container mw-800px'>
      <section className='mb-15'>
        {error && <div className='alert alert-danger mb-5'>{error}</div>}

        {/* default image: {isDefaultImage ? 'yes' : 'no'} */}

        <fieldset className='mb-5'>
          <label className='fs-5 fw-bold py-3'>Import product image:</label>
          <div className='ps-10'>
            <ImageUpload
              onImagesChanged={setImagesList}
              defaultImages={defaultImagesList}
              maxNumber={1}
              customDesign
            />
          </div>
        </fieldset>

        <fieldset className='mb-5'>
          <label className='fs-5 fw-bold py-3'>Select video resolution:</label>
          <div className='ps-10'>
            <CardsSelector
              options={MediaResolutions}
              optionLabel='label'
              optionValue='res'
              onChange={(e: any) => updateVideo({resolution: e.res})}
            />
            {/* <Dropdown
              className='bg-light mb-3 mb-lg-0 border-0 w-100 mw-200px fw-bold text-gray-700'
              value={video.resolution || undefined}
              onChange={(e: any) => updateVideo({resolution: e.value})}
              options={VideoResolutions}
              optionLabel='label'
              optionValue='res'
              itemTemplate={(item) => (
                <div className='d-flex align-items-center'>
                  <div className='me-3 fw-semibold'>{item.label}</div>

                  <div className={`bg-light ${item.iconClass}`}></div>
                </div>
              )}
              placeholder='Choose resolution'
              showClear
              disabled={submitting}
            /> */}
          </div>
        </fieldset>
      </section>

      <footer className='d-flex justify-content-end'>
        <button className='btn btn-primary' disabled={submitting} onClick={submit}>
          {!submitting && (
            <span className='d-flex align-items-center'>
              Continue
              <i className='ms-2 ki-duotone ki-arrow-right fs-3'>
                <span className='path1' />
                <span className='path2' />
              </i>
            </span>
          )}
          {submitting && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {progress === 100 && <span>Removing background ...</span>}
              {progress < 100 && <span> Uploading image ... ({progress})% </span>}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </footer>
    </div>
  );
};

export default MediaStartStep;
